import {send} from 'ajax';
import {getCookie} from 'browser';

const _el = window.location.origin.includes('textsfromsanta'); 
//const _el = true; // for testing on dev

const run_gtag = true;
//const run_gtag = false;

function ready(fn) {
  if (document.readyState != 'loading'){
    fn();
  }
  else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(function(){
  sendPageView();
})

function sendPageView(){
 
  console.log('sendPageView'); 
  let ip_address_value = document.getElementById('ip_address');
  
  if (_el) {
  
    let unique_id = ip_address_value ? Math.floor(Date.now() / 1000) + ip_address_value.innerHTML : Math.floor(Date.now() / 1000);
  
    console.log('processing pageview id: ' + unique_id);
   
    if(typeof fbq === 'undefined') {
        !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');
    
        fbq('init', '721195094622724');
        fbq('track', 'PageView', {}, {eventID: unique_id});
        send("GET", "/santa/third_party_pageview?fbp=" + getCookie('_fbp') + '&fbc=' + getCookie('_fbc') + '&website=' + window.location.href + '&unique_id=' + unique_id  + '&ttp=' + getCookie('_ttp'));
    }
    else {
        fbq('track', 'PageView', {}, {eventID: unique_id});
        send("GET", "/santa/third_party_pageview?fbp=" + getCookie('_fbp') + '&fbc=' + getCookie('_fbc') + '&website=' + window.location.href + '&unique_id=' + unique_id + '&ttp=' + getCookie('_ttp'));
    }

    if(typeof snaptr === 'undefined') {
      !function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function(){a.handleRequest?
      a.handleRequest.apply(a,arguments):a.queue.push(arguments)};a.queue=[];var s='script';var r=t.createElement(s);r.async=!0;
      r.src=n;var u=t.getElementsByTagName(s)[0];u.parentNode.insertBefore(r,u);}(window,document,'https://sc-static.net/scevent.min.js');
 
       if (window.location.pathname.includes('text_package')) {
         snaptr('init', '5f99a6e9-6562-4ca2-941e-0fcac47e7b74');
         //snaptr('init', '5f99a6e9-6562-4ca2-941e-0fcac47e7b74', {'user_email': '__INSERT_USER_EMAIL__'});
       }
       else {
         snaptr('init', '5f99a6e9-6562-4ca2-941e-0fcac47e7b74');
       }
      snaptr('track', 'PAGE_VIEW');
    }
    else { 
      //snaptr('track', 'PAGE_VIEW');
    }

    dataLayer.push({'event':'pageView'});console.log('sending pageView for GtagManager'); 
    ttq.track('ViewContent', { "contents": [ { "content_name": "pageView" } ], "event_id": unique_id , "context": {"user": { "ttp": getCookie('_ttp')}}});

  } 
  else {
    console.log('not processing pageview - dev');
  }
}

function recapCallback(response) {
  console.log('recapCallback', grecaptcha.getResponse());
  document.getElementById('submit-payment_button').classList.remove('hidden');
  document.getElementById('recaptcha_div').classList = 'hidden';
}

function recapExpire() {
  console.log('recapExpire');
  document.getElementById('submit-payment_button').classList.add('hidden');
  document.getElementById('recaptcha_div').classList = '';
}

window.recapCallback = recapCallback;
window.recapExpire = recapExpire;
